import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import mobile from "../../assets/images/services/mobile.png"

const ItSolutionsPage = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT"
                title="Web App Development Outsourcing Projects India"
                description="Get web app development projects directly from a company situated in the United States, with prices starting at $500,000 USD" />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                {/* <ITSolutionArea
                    data={{
                        ...content["service-section"],
                        items: data.allItSolution.nodes,
                    }}
                    space={2}
                /> */}

                <div className="service-container">
                    <div className="left-sec">
                        <h1>App Development</h1>
                        <p>
                            Mobile apps have become a crucial channel for businesses to engage with their customers and enhance their brand presence. At OurCompany, we specialize in creating innovative and feature-rich mobile apps for iOS and Android platforms. Whether you need a native app, a cross-platform app, or a progressive web app, our team of experienced developers will work closely with you to bring your ideas to life and deliver a seamless mobile experience for your users.
                        </p>
                    </div>
                    <div className="right-sec">
                        <img src={mobile} alt="Custom Software Development" className="image" />
                    </div>
                </div>

                <div className="service-sub-container">
                    <p>Our mobile app development process is designed to deliver high-quality, user-centric mobile applications that meet your business objectives. Here's how we do it:</p>
                    <ul>
                        <li className="mt-2"><strong>Strategy & Planning:</strong> We start by understanding your business goals and target audience to define the mobile app strategy and roadmap.</li>
                        <li className="mt-2"><strong>UI/UX Design:</strong> Our designers create intuitive and visually appealing user interfaces that enhance the usability and engagement of your mobile app.</li>
                        <li className="mt-2"><strong>Development:</strong> Using the latest mobile technologies and frameworks such as React Native, Flutter, or Swift, we develop high-quality mobile apps that are optimized for performance and security.</li>
                        <li className="mt-2"><strong>Testing:</strong> We conduct rigorous testing to ensure that your mobile app is bug-free, responsive, and delivers a seamless user experience across different devices and screen sizes.</li>
                        <li className="mt-2"><strong>Deployment:</strong> Once the mobile app is ready, we assist you in deploying it to the App Store, Google Play Store, or other distribution channels, and provide ongoing support and maintenance to ensure its success.</li>
                    </ul>
                </div>


                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query AppDevelopmentPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

ItSolutionsPage.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default ItSolutionsPage;
